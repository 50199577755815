// Import global CSS file
import "@/assets/css/global.scss";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Vue from "vue";
import Notifications from "vue-notification";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Register input custom components
Vue.component("CustomAutoCompleteField", () =>
  import("@/components/inputs/customAutoCompleteField")
);
Vue.component("CustomButton", () => import("@/components/inputs/customButton"));
Vue.component("CustomCheckBox", () =>
  import("@/components/inputs/customCheckBox")
);
Vue.component("CustomDatePickerField", () =>
  import("@/components/inputs/customDatePickerField")
);
Vue.component("CustomLabel", () => import("@/components/inputs/customLabel"));
Vue.component("CustomSelectField", () =>
  import("@/components/inputs/customSelectField")
);
Vue.component("CustomSwitch", () => import("@/components/inputs/customSwitch"));
Vue.component("CustomTextAreaField", () =>
  import("@/components/inputs/customTextAreaField")
);
Vue.component("CustomTextField", () =>
  import("@/components/inputs/customTextField")
);
Vue.component("CustomTimePickerField", () =>
  import("@/components/inputs/customTimePickerField")
);

Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
