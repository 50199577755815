const Joi = require("joi");

// Schemas
const createSchema = Joi.object({
  name: Joi.string().required(),
  abbreviation: Joi.string().required(),
  email: Joi.string().required(),
  first_name: Joi.string().allow(null, ""),
  last_name: Joi.string().allow(null, ""),
  full_name: Joi.string().allow(null, ""),
  rate: Joi.number().integer().required(),
});

const updateSchema = Joi.object({
  name: Joi.string().required(),
  abbreviation: Joi.string().required(),
  email: Joi.string().required(),
  first_name: Joi.string().allow(null, ""),
  last_name: Joi.string().allow(null, ""),
  full_name: Joi.string().allow(null, ""),
  rate: Joi.number().integer().required(),
});

export { createSchema, updateSchema };
