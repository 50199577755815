import { createSchema, updateSchema } from "@/schemas/schemaTrackers";
import { setPath } from "@/services/helpers";
import {
  notifyCreateTrackerSuccess,
  notifyRemoveTrackerError,
  notifyRemoveTrackerSuccess,
} from "@/services/notification/notificationTracker";
import httpClient from "@/store/httpClient";
import { DateTime } from "luxon";

const endpoint = "/u/trackers";

// Actions
const actions = {
  async createItems({ commit }, payload) {
    commit("START_LOADING", "createOps");

    const { error, value } = createSchema.validate(payload);

    if (!error) {
      return httpClient
        .post(endpoint, [value])
        .then((response) => {
          if (response.status === 201) {
            commit("APPEND_ITEMS", response);
            commit("STOP_LOADING", "createOps");
            // commit("FLUSH_FIELD", "createPayload");
            commit("FLUSH_SELECTED_FIELD", "createPayload");
            notifyCreateTrackerSuccess();
            // commit("HIDE_CREATE_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "createOps");
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "createOps");
    }
  },
  async removeItem({ commit }, payload) {
    commit("START_LOADING", "removeOps");
    httpClient
      .delete(`${endpoint}/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_ITEM", response);
          commit("STOP_LOADING", "removeOps");
          notifyRemoveTrackerSuccess();
          commit("HIDE_VIEW_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "removeOps");
        notifyRemoveTrackerError();
      });
  },
  async retrieveItems({ commit }, dateRange) {
    commit("START_LOADING", "retrieveOps");

    httpClient
      .get(endpoint, {
        params: {
          date_start: dateRange.start.date,
          date_end: dateRange.end.date,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("STOP_LOADING", "retrieveOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveOps");
      });
  },
  async updateItem({ commit }, payload) {
    commit("START_LOADING", "updateOps");

    //   Validate payload
    const { error, value } = updateSchema.validate(payload.user);

    // Make HTTP call
    if (!error) {
      httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ITEM", response);
            commit("STOP_LOADING", "updateOps");
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateOps");
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "updateOps");
    }
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    response.data.items.forEach((item) => {
      item.datetime_start = DateTime.fromISO(item.datetime_start).toFormat(
        "yyyy-LL-dd HH:mm"
      );
      item.datetime_end = DateTime.fromISO(item.datetime_end).toFormat(
        "yyyy-LL-dd HH:mm"
      );
      state.trackers.push(item);
    });
  },
  FLUSH_FIELD(state, name) {
    state[name] = Object.assign({}, {});
  },
  FLUSH_SELECTED_FIELD(state) {
    state["end_date"] = Object.assign({}, {});
    state["end_time"] = Object.assign({}, {});
    state["start_date"] = Object.assign({}, {});
    state["start_time"] = Object.assign({}, {});
  },
  FLUSH_ITEMS(state) {
    state.trackers = [];
  },
  FLUSH_ITEM(state) {
    state.tracker = Object.assign({}, {});
  },
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  UPDATE_ITEM(state, response) {
    let index = state.trackers.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.trackers[index], response.data);
    }
  },
  REMOVE_ITEM(state, response) {
    state.trackers = state.trackers.filter(
      (element) => element.id != response.data.id
    );
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveOps.serverItemsLength = response.data.meta.total;
  },
  UPDATE_DATETIME_FORM(state, { payload }) {
    state[payload.form][payload.event.target.id] = payload;
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
  UPDATE_VALUE_FORM(state, { payload }) {
    state[payload.form][payload.name] = payload.value;
  },
  UPDATE_ITEM_FORM(state, { payload }) {
    state[payload.form][payload.name] = payload.item[payload.property];
  },
};
// State
const state = {
  trackers: [],
  tracker: {},
  createPayload: {
    datetime_start: null,
    datetime_end: null,
    category_id: null,
    customer_id: null,
    client_id: null,
    task_id: null,
    description: "",
  },
  createOps: {
    dialog: false,
    isLoading: false,
  },
  removeOps: {
    dialog: false,
    isLoading: false,
  },
  retrieveOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    isLoading: false,
  },
  viewOps: {
    dialog: false,
    isLoading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
