import { DateTime } from "luxon";

const dateFormatMapper = {
  "DD/MM/YYYY": "dd/LL/yyyy",
  "DD/MMM/YYYY": "dd/LLL/yyyy",
  "MM/DD/YYYY": "LL/dd/yyyy",
  "MMM/DD/YYYY": "LLL/dd/yyyy",
  "YYYY/MMM/DD": "yyyy/LLL/dd",
};

const timeFormatMapper = {
  "HH:mm:ss": "HH:mm:ss",
  "hh:mm:ss": "hh:mm:ss a",
};

export class ReportServices {
  static formatDate(datetime, format) {
    let isoDateTime = DateTime.fromISO(datetime);
    return isoDateTime.toFormat(dateFormatMapper[format]);
  }

  static formatTime(datetime, format) {
    let isoDateTime = DateTime.fromISO(datetime);
    return isoDateTime.toFormat(timeFormatMapper[format]);
  }

  static formatDuration(duration) {
    return duration.toFormat("hh:mm:ss");
  }

  static computeBillable(duration, rate) {
    if (duration % 10 == 0) {
      let quotient = Math.floor(duration / 10);
      let minuteRate = rate / 60;
      let billable = quotient * (minuteRate * 10);

      return billable;
    }
  }
}

export default ReportServices;
