// import Axios from "axios";
// Import store modules
import authsMgmt from "@/store/modules/authsMgmt.module";
import calendarEventsMgmt from "@/store/modules/calendarEventsMgmt.module";
import categoriesAdminMgmt from "@/store/modules/categoriesAdminMgmt.module";
import categoriesUserMgmt from "@/store/modules/categoriesUserMgmt.module";
import clientsAdminMgmt from "@/store/modules/clientsAdminMgmt.module";
import customersAdminMgmt from "@/store/modules/customersAdminMgmt.module";
import customersUserMgmt from "@/store/modules/customersUserMgmt.module";
import reportsUserMgmt from "@/store/modules/reportsUserMgmt.module";
import tasksAdminMgmt from "@/store/modules/tasksAdminMgmt.module";
import tasksUserMgmt from "@/store/modules/tasksUserMgmt.module";
import trackersMgmt from "@/store/modules/trackersMgmt.module";
import trackersUserMgmt from "@/store/modules/trackersUserMgmt.module";
import usersAdminMgmt from "@/store/modules/usersAdminMgmt.module";
import userSelfMgmt from "@/store/modules/userSelfMgmt.module";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
// import TokenServices from "@/services/TokenServices";

Vue.use(Vuex);

// Setup persistent store on applicable modules
const dataState = createPersistedState({
  key: "avsbDataStore",
  paths: ["authsMgmt"],
});

export default new Vuex.Store({
  actions: {},
  modules: {
    authsMgmt,
    categoriesAdminMgmt,
    categoriesUserMgmt,
    calendarEventsMgmt,
    clientsAdminMgmt,
    customersAdminMgmt,
    customersUserMgmt,
    reportsUserMgmt,
    tasksAdminMgmt,
    tasksUserMgmt,
    trackersMgmt,
    trackersUserMgmt,
    usersAdminMgmt,
    userSelfMgmt,
  },
  mutations: {
    TOGGLE_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },
  plugins: [dataState],
  state: {
    drawer: false,
  },
});
