import Axios from "axios";
import TokenServices from "@/services/TokenServices";
import store from "@/store";

const httpClient = Axios.create({
  baseURL: process.env.VUE_APP_HTTP_CLIENT_API_BASE_URL,
  timeout: process.env.VUE_APP_HTTP_CLIENT_TIMEOUT,
  withCredentials: true,
});

// Axios request interceptors
httpClient.interceptors.request.use((config) => {
  const csrfToken = TokenServices.getCookie("csrf_access_token");
  if (["delete", "post", "put"].includes(config.method)) {
    config.headers["X-CSRF-TOKEN"] = csrfToken;
  }
  return config;
});

// Axios response interceptors
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let originalRequestConfig = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.message == "Invalid JWT Token(s)"
    ) {
      // Handle invalid JWT tokens by logging out and redirecting to login page.
      store.dispatch("authsMgmt/logout", error.response);
    } else if (
      error.response.status === 401 &&
      error.response.data.message === "Expired JWT Token(s)"
    ) {
      // Handle expired JWT tokens by redirecting to refresh token endpoint.
      return httpClient
        .get(
          `${process.env.VUE_APP_HTTP_CLIENT_API_BASE_URL}/auth/tokens/refresh`
        )
        .then((response) => {
          if (response.status === 200) {
            // Re-populate the X-CSRF-TOKEN header with the refreshed tokens
            originalRequestConfig.headers["X-CSRF-TOKEN"] =
              TokenServices.getCookie("csrf_access_token");
            return Axios(originalRequestConfig);
          }
        });
    } else if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // if (error.response.status === 401) {
    //   // Handle expired JWT tokens by automatically refreshing and returning them
    //   return httpClient
    //     .get(
    //       `${process.env.VUE_APP_HTTP_CLIENT_API_BASE_URL}/auth/tokens/refresh`
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         // Re-populate the X-CSRF-TOKEN header with the refreshed tokens
    //         originalRequestConfig.headers["X-CSRF-TOKEN"] =
    //           TokenServices.getCookie("csrf_access_token");
    //         return Axios(originalRequestConfig);
    //       }
    //     });
    // }

    return Promise.reject(error);
  }
);

export default httpClient;
