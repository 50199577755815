import {
  updateSelfCredentialSchema,
  updateSelfSchema,
} from "@/schemas/schemaSelf";
import { setPath } from "@/services/helpers";
import {
  notifyUpdateCredentialError,
  notifyUpdateCredentialSuccess,
  notifyUpdateSelfError,
  notifyUpdateSelfSuccess,
} from "@/services/notification/notificationSelf";
import httpClient from "@/store/httpClient";
import Vue from "vue";

const endpoint = "/u/users/self";

// Actions
const actions = {
  async enableGoogleCalendarIntegration({ commit }, value) {
    return httpClient
      .put(`${endpoint}/settings/google`, value)
      .then((response) => {
        console.log(response);
        commit("LOG", response);
      });
  },
  async enableGoogleDocsIntegration() {
    const googleUser = Vue.gAuth.GoogleAuth.currentUser.get();

    return googleUser
      .grantOfflineAccess({
        scope: "https://www.googleapis.com/auth/documents",
      })
      .then((response) => {
        return httpClient
          .post(`${endpoint}/oauth/google/integrations/docs`, response)
          .then((response) => {
            return response;
          });
      })
      .catch((error) => {
        return error;
      });
  },
  async enableGoogleGmailIntegration() {
    const googleUser = Vue.gAuth.GoogleAuth.currentUser.get();

    return googleUser
      .grantOfflineAccess({
        scope: "https://mail.google.com/",
      })
      .then((response) => {
        return httpClient
          .post(`${endpoint}/oauth/google/integrations/gmail`, response)
          .then((response) => {
            return response;
          });
      })
      .catch((error) => {
        return error;
      });
  },
  async enableGoogleSheetsIntegration() {
    const googleUser = Vue.gAuth.GoogleAuth.currentUser.get();

    return googleUser
      .grantOfflineAccess({
        scope: "https://www.googleapis.com/auth/spreadsheets",
      })
      .then((response) => {
        return httpClient
          .post(`${endpoint}/oauth/google/integrations/sheets`, response)
          .then((response) => {
            return response;
          });
      })
      .catch((error) => {
        return error;
      });
  },
  async retrieveSelf({ commit }) {
    httpClient.get(endpoint).then((response) => {
      commit("POPULATE_SELF", response);
      commit("POPULATE_ITEM", {
        form: "updatePayload",
        item: response.data,
      });
    });
  },
  async updateSelf({ commit }, payload) {
    commit("START_LOADING", "updateOps");

    const { error, value } = updateSelfSchema.validate(payload);

    if (!error) {
      return httpClient
        .put(endpoint, value)
        .then((response) => {
          if (response.status === 200) {
            commit("STOP_LOADING", "updateOps");
            commit("POPULATE_SELF", response);
            commit("POPULATE_ITEM", {
              form: "updatePayload",
              item: response.data,
            });
            notifyUpdateSelfSuccess();

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateOps");
          notifyUpdateSelfError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "updateOps");
      notifyUpdateSelfError(error);
    }
  },
  async updateSelfCredential({ commit }, payload) {
    commit("START_LOADING", "updateCredentialOps");
    const { error, value } = updateSelfCredentialSchema.validate(payload);

    if (!error) {
      return httpClient
        .put(`${endpoint}/password`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("FLUSH_FIELD", {
              name: "updateCredentialPayload",
              initialState: {
                current_password: null,
                new_password: null,
                confirm_password: null,
              },
            });
            commit("STOP_LOADING", "updateCredentialOps");
            notifyUpdateCredentialSuccess();

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateCredentialOps");
          notifyUpdateCredentialError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "updateCredentialOps");
      notifyUpdateCredentialError(error);
    }
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  LOG(value) {
    console.log(value);
  },
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  // Data operations
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  POPULATE_SELF(state, response) {
    state.user = Object.assign({}, response.data);
  },
  FLUSH_FIELD(state, options) {
    state[options.name] = Object.assign({}, options.initialState);
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const initialState = {
  user: {},
  updatePayload: {},
  updateCredentialPayload: {
    current_password: null,
    new_password: null,
    confirm_password: null,
  },
  updateOps: {
    isLoading: false,
  },
  updateCredentialOps: {
    isLoading: false,
  },
};

const state = { ...initialState };

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
