const Joi = require("joi");

// Schemas
const createSchema = Joi.object({
  name: Joi.string().required(),
  customer_id: Joi.number().allow(null),
});

const updateSchema = Joi.object({
  name: Joi.string().required(),
  customer_id: Joi.number().allow(null),
});

export { createSchema, updateSchema };
