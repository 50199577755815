import { createSchema, updateSchema } from "@/schemas/schemaCategories";
import { setPath } from "@/services/helpers";
import {
  notifyCreateCategoryError,
  notifyCreateCategorySuccess,
  notifyDisableCategoryError,
  notifyDisableCategorySuccess,
  notifyEnableCategoryError,
  notifyEnableCategorySuccess,
  notifyRemoveCategoryError,
  notifyRemoveCategorySuccess,
  notifyUpdateCategoryError,
  notifyUpdateCategorySuccess,
} from "@/services/notification/notificationCategory";
import httpClient from "@/store/httpClient";

const endpoint = "/a/categories";

// Actions
const actions = {
  async createItems({ commit }, payload) {
    commit("START_LOADING", "createOps");

    const { error, value } = createSchema.validate(payload);

    if (!error) {
      return httpClient
        .post(endpoint, [value])
        .then((response) => {
          if (response.status === 201) {
            commit("APPEND_ITEMS", response);
            commit("FLUSH_FIELD", "createPayload");
            commit("STOP_LOADING", "createOps");
            notifyCreateCategorySuccess();
            commit("HIDE_CREATE_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "createOps");
          notifyCreateCategoryError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "createOps");
      notifyCreateCategoryError(error);
    }
  },
  async disableItem({ commit }, payload) {
    commit("START_LOADING", "disableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/disable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "disableOps");
          notifyDisableCategorySuccess();
          commit("HIDE_DISABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "disableOps");
        notifyDisableCategoryError(error);
      });
  },
  async enableItem({ commit }, payload) {
    commit("START_LOADING", "enableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/enable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "enableOps");
          notifyEnableCategorySuccess();
          commit("HIDE_ENABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "enableOps");
        notifyEnableCategoryError(error);
      });
  },
  async removeItem({ commit }, payload) {
    commit("START_LOADING", "removeOps");

    httpClient
      .delete(`${endpoint}/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_ITEM", response);
          commit("STOP_LOADING", "removeOps");
          notifyRemoveCategorySuccess();
          commit("HIDE_REMOVE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "removeOps");
        notifyRemoveCategoryError();
      });
  },
  async retrieveItems({ commit }, params) {
    commit("START_LOADING", "retrieveOps");

    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "retrieveOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveOps");
      });
  },
  async searchItems({ commit }, params) {
    commit("START_LOADING", "searchOps");

    httpClient
      .get(`${endpoint}/search`, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "searchOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "searchOps");
      });
  },
  async updateItem({ commit }, payload) {
    commit("START_LOADING", "updateOps");

    const { error, value } = updateSchema.validate(payload.category);

    if (!error) {
      return httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ITEMS", response);
            commit("STOP_LOADING", "updateOps");
            notifyUpdateCategorySuccess();
            commit("HIDE_VIEW_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateOps");
          notifyUpdateCategoryError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "updateOps");
      notifyUpdateCategoryError(error);
    }
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = false;
  },
  HIDE_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = false;
  },
  HIDE_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = true;
  },
  SHOW_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = true;
  },
  SHOW_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    response.data.items.forEach((item) => {
      state.categories.push(item);
    });
  },
  BULK_OPS_UPDATE(state, payload) {
    state.bulkPayload = Object.assign((state.bulkPayload = payload));
  },
  FLUSH_ITEMS(state) {
    state.categories = [];
  },
  FLUSH_FIELD(state, name) {
    state[name] = Object.assign({}, {});
  },
  REMOVE_ITEM(state, response) {
    state.categories = state.categories.filter(
      (element) => element.id != response.data.id
    );
  },
  UPDATE_ITEMS(state, response) {
    let index = state.categories.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.categories[index], response.data);
    }
  },
  APPEND_SEARCH_ITEMS(state, response) {
    if (response.data.items.length > 0) {
      state.searchItems = []; // Flush array before loading data
      response.data.items.forEach((item) => {
        state.searchItems.push(item);
      });
    } else {
      console.log("No matching records found.");
    }
  },
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  POPULATE_CATEGORY(state, obj) {
    state.category = Object.assign({}, state.categories[obj.index]);
  },
  FLUSH_ITEM(state) {
    state.category = Object.assign({}, {});
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveOps.serverItemsLength = response.data.meta.total;
    state.searchOps.serverItemsLength = response.data.meta.total;
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const state = {
  categories: [],
  category: {},
  searchItems: [],
  bulkPayload: [],
  createPayload: {},
  disablePayload: {},
  enablePayload: {},
  removePayload: {},
  createOps: {
    dialog: false,
    isLoading: false,
  },
  disableOps: {
    dialog: false,
    isLoading: false,
  },
  enableOps: {
    dialog: false,
    isLoading: false,
  },
  removeOps: {
    dialog: false,
    isLoading: false,
  },
  retrieveOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  searchOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    isLoading: false,
  },
  viewOps: {
    dialog: false,
    isLoading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
