export class TokenServices {
  static getCookie(item) {
    let cookies = document.cookie.split("; ");
    let token = "";
    cookies.forEach((cookie) => {
      let cookieKey = cookie.split("=")[0];
      let cookieValue = cookie.split("=")[1];
      if (cookieKey === item) {
        token = cookieValue;
      }
    });

    return token;
  }
}

export default TokenServices;
