const { DateTime } = require("luxon");

function toLocalDateTime(utcDateTime, format = "dd/LLL/yyyy") {
  let awareDateTime = DateTime.fromISO(utcDateTime);
  return awareDateTime.toLocal().toFormat(format);
}

function popAttribute(object, attributeName) {
  let attribute = object[attributeName];
  object.delete(attributeName);
  return attribute;
}

function isDisabled(item) {
  return item ? true : false;
}
function isBulkSelected(item) {
  return item.length > 0 ? false : true;
}

function isOAuthUser(user) {
  return user.type > 0;
}

// JS Helper Functions
// Source: https://stackoverflow.com/a/43849204
function resolvePath(object, path, defaultValue) {
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);
}
// Usage example:
//      resolvePath(window,'document.body') => <body>
//      resolvePath(window,'document.body.xyz') => undefined
//      resolvePath(window,'document.body.xyz', null) => null
//      resolvePath(window,'document.body.xyz', 1) => 1

// Source: https://stackoverflow.com/a/43849204
function setPath(object, path, value) {
  path
    .split(".")
    .reduce(
      (o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
      object
    );
}
// Usage example:
//      let myVar = {}
//      setPath(myVar, 'a.b.c', 42) => 42
//      console.log(myVar) => {a: {b: {c: 42}}}

// VueJS Events Explorers
function handleBlurEvent(event) {
  console.log("BlurEvent", event);
}
function handleChangeEvent(event) {
  console.log("ChangeEvent", event);
}
function handleClickEvent(event) {
  console.log("ClickEvent", event);
}
function handleFocusEvent(event) {
  console.log("FocusEvent", event);
}
function handleInputEvent(event) {
  console.log("InputEvent", event);
}
function handleKeyDownEvent(event) {
  console.log("KeyDownEvent", event);
}

export {
  isDisabled,
  isBulkSelected,
  isOAuthUser,
  handleBlurEvent,
  handleChangeEvent,
  handleClickEvent,
  handleFocusEvent,
  handleInputEvent,
  handleKeyDownEvent,
  popAttribute,
  resolvePath,
  setPath,
  toLocalDateTime,
};
