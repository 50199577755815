import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyUpdateCredentialError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Credential Operation",
    type: "warn",
  });
}
function notifyUpdateCredentialSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Credential successfully updated.",
    title: "Credential Operation",
    type: "success",
  });
}
function notifyUpdateSelfError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Profile Operation",
    type: "warn",
  });
}
function notifyUpdateSelfSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Profile successfully updated.",
    title: "Profile Operation",
    type: "success",
  });
}

export {
  notifyUpdateCredentialError,
  notifyUpdateCredentialSuccess,
  notifyUpdateSelfError,
  notifyUpdateSelfSuccess,
};
