import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateUserError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "User Operation",
    type: "warn",
  });
}
function notifyCreateUserSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "User successfully created.",
    title: "User Operation",
    type: "success",
  });
}
function notifyEnableUserError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "User Operation",
    type: "warn",
  });
}
function notifyEnableUserSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "User successfully enabled.",
    title: "User Operation",
    type: "success",
  });
}
function notifyDisableUserError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "User Operation",
    type: "warn",
  });
}
function notifyDisableUserSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "User successfully disabled.",
    title: "User Operation",
    type: "success",
  });
}
function notifyRemoveUserError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "User Operation",
    type: "warn",
  });
}
function notifyRemoveUserSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "User successfully removed.",
    title: "User Operation",
    type: "success",
  });
}
function notifyUpdateCredentialError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Credential Operation",
    type: "warn",
  });
}
function notifyUpdateCredentialSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Credential successfully updated.",
    title: "Credential Operation",
    type: "success",
  });
}
function notifyUpdateUserError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "User Operation",
    type: "warn",
  });
}
function notifyUpdateUserSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "User successfully updated.",
    title: "User Operation",
    type: "success",
  });
}

export {
  notifyCreateUserError,
  notifyCreateUserSuccess,
  notifyEnableUserError,
  notifyEnableUserSuccess,
  notifyDisableUserError,
  notifyDisableUserSuccess,
  notifyRemoveUserError,
  notifyRemoveUserSuccess,
  notifyUpdateCredentialError,
  notifyUpdateCredentialSuccess,
  notifyUpdateUserError,
  notifyUpdateUserSuccess,
};
