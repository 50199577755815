import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateTaskError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Task Operation",
    type: "warn",
  });
}
function notifyCreateTaskSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Task successfully created.",
    title: "Task Operation",
    type: "success",
  });
}
function notifyEnableTaskError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Task Operation",
    type: "warn",
  });
}
function notifyEnableTaskSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Task successfully enabled.",
    title: "Task Operation",
    type: "success",
  });
}
function notifyDisableTaskError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Task Operation",
    type: "warn",
  });
}
function notifyDisableTaskSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Task successfully disabled.",
    title: "Task Operation",
    type: "success",
  });
}
function notifyRemoveTaskError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Task Operation",
    type: "warn",
  });
}
function notifyRemoveTaskSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Task successfully removed.",
    title: "Task Operation",
    type: "success",
  });
}
function notifyUpdateTaskError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Task Operation",
    type: "warn",
  });
}
function notifyUpdateTaskSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Task successfully updated.",
    title: "Task Operation",
    type: "success",
  });
}

export {
  notifyCreateTaskError,
  notifyCreateTaskSuccess,
  notifyEnableTaskError,
  notifyEnableTaskSuccess,
  notifyDisableTaskError,
  notifyDisableTaskSuccess,
  notifyRemoveTaskError,
  notifyRemoveTaskSuccess,
  notifyUpdateTaskError,
  notifyUpdateTaskSuccess,
};
