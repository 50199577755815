import { setPath } from "@/services/helpers";
import httpClient from "@/store/httpClient";

const endpoint = "/u/categories";

// Actions
const actions = {
  async retrieveItems({ commit }, options) {
    commit("START_LOADING", "retrieveCategoryOps");

    let params = {};

    if (options.meta.page == null) {
      params = {
        page: 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else if (options.meta.page != null && options.meta.has_next) {
      params = {
        page: options.meta.page + 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else {
      return;
    }

    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("APPEND_ITEMS", response);
          commit("UPDATE_PAGINATION_METADATA", {
            form: "retrieveCategoryOps",
            field: "meta",
            response: response,
          });
          commit("STOP_LOADING", "retrieveCategoryOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveCategoryOps");
      });
  },
  async searchItem({ commit, dispatch }, payload) {
    commit("START_LOADING", "searchOps");
    httpClient
      .post(`${endpoint}/search`, {
        name: payload,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch("appendSearchItems", response);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "searchOps");
      });
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    response.data.items.forEach((item) => {
      state.categories.push(item);
    });
  },
  FLUSH_ITEMS(state) {
    state.categories = [];
  },
  POPULATE_CATEGORY(state, obj) {
    state.category = Object.assign({}, state.categories[obj.index]);
  },
  FLUSH_ITEM(state) {
    state.category = Object.assign({}, {});
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveCategoryOps.serverItemsLength = response.data.meta.total;
  },
  UPDATE_PAGE_NUMBER(state, response) {
    state.retrieveCategoryOps.page = response.data.meta.page;
  },
  UPDATE_PAGINATION_METADATA(state, payload) {
    state[payload.form][payload.field] = Object.assign(
      {},
      payload.response.data.meta
    );
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
  UPDATE_ITEM_FORM(state, { payload }) {
    if (!payload.property == null) {
      state[payload.form][payload.name] = payload.item[payload.property];
    } else {
      state[payload.form][payload.name] = payload.item;
    }
  },
};
// State
const state = {
  categories: [],
  category: {},
  searchItems: [],
  serverItemsLength: 0,
  retrieveCategoryOps: {
    isLoading: false,
    serverItemsLength: 0,
    meta: {
      has_next: null,
      page: null,
      pages: null,
      total: null,
    },
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
