import { setPath } from "@/services/helpers";
import httpClient from "@/store/httpClient";

const endpoint = "/events";

// Actions
const actions = {
  async appendCalendarEvents({ commit }, data) {
    commit("APPEND_CALENDAR_EVENTS", data);
  },
  async retrieveCalendarEvents({ dispatch }) {
    httpClient
      .get(endpoint)
      .then((response) => {
        if (response.status === 200) {
          dispatch("appendCalendarEvents", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].loading = true;
  },
  STOP_LOADING(state, name) {
    state[name].loading = false;
  },
  //   Dialog operations
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  // Data operations
  APPEND_CALENDAR_EVENTS(state, response) {
    state.calendarEvents = []; //Flush array before loading data
    response.data.items.forEach((item) => {
      state.calendarEvents.push(item);
    });
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const state = {
  items: [],
  item: {},
  sampleData: [
    {
      name: "testEvent",
      start: "startDateTime",
      end: "endDateTime",
      color: "eventColour",
      timed: "timed",
    },
  ],
  createOps: {
    dialog: false,
    loading: false,
  },
  removeOps: {
    dialog: false,
    loading: false,
  },
  retrieveOps: {
    loading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    loading: false,
  },
  viewOps: {
    dialog: false,
    loading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
