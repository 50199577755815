const Joi = require("joi");

// Schemas
const createSchema = Joi.object({
  name: Joi.string().required(),
});

const updateSchema = Joi.object({
  name: Joi.string().required(),
});

export { createSchema, updateSchema };
