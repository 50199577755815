import router from "@/router";
import { loginSchema } from "@/schemas/schemaAuths";
import { setPath } from "@/services/helpers";
import httpClient from "@/store/httpClient";

const endpoint = "/auth";
const googleOAuthCallbackEndpoint = "/a/users/oauth/google/callback";

// Actions
const actions = {
  async login({ commit }, credentials) {
    commit("START_LOADING", "loginOps");

    const { error, value } = loginSchema.validate(credentials);

    if (!error) {
      return httpClient
        .post(`${endpoint}/login`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("LOGIN", response);
            router.push(router.currentRoute.query.redirect || { name: "Home" });
            commit("STOP_LOADING", "loginOps");

            return response;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // Insert notifications here
          }
          commit("STOP_LOADING", "loginOps");
        });
    } else {
      // Throw notification error here
      commit("STOP_LOADING", "loginOps");
    }
  },
  //   async newLogin({ commit }, credentials) {},
  async logout({ commit }) {
    commit("START_LOADING", "logoutOps");

    httpClient
      .get(`${endpoint}/logout`)
      .then((response) => {
        if (response.status == 200) {
          commit("LOGOUT");
          router.push({ name: "Login" });
        }
        commit("STOP_LOADING", "loginOps");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          // Insert notifications here
        }
        commit("STOP_LOADING", "loginOps");
      });
  },
  async googleISLogin({ commit }, data) {
    httpClient
      .post(googleOAuthCallbackEndpoint, {
        token: data.credential,
      })
      .then((response) => {
        if (response.status == 200) {
          commit("LOGIN", response);
          router.push(router.currentRoute.query.redirect || { name: "Home" });
        }
      })
      .catch((error) => {
        //   console.log("Error");
        console.log(error);
      });
  },
  //   async googleOAuthLogin({ commit }) {
  //     Vue.gAuth.getAuthCode().then((authorization_code) => {
  //       httpClient
  //         .post(googleOAuthCallbackEndpoint, {
  //           authorization_code: authorization_code,
  //         })
  //         .then((response) => {
  //           if (response.status == 200) {
  //             commit("LOGIN", response);
  //             router.push(router.currentRoute.query.redirect || { name: "Home" });
  //           }
  //         })
  //         .catch((error) => {
  //           //   console.log("Error");
  //           console.log(error);
  //         });
  //     });
  //   },
};
// Getters
const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  // Data operations
  LOGIN(state, response) {
    state.isAuthenticated = true;
    state.user = Object.assign({}, response.data.user);
  },
  LOGOUT(state) {
    state.isAuthenticated = false;
    state.user = Object.assign({}, {});
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const state = {
  isAuthenticated: false,
  loginOps: {
    isLoading: false,
  },
  logoutOps: {
    isLoading: false,
  },
  userStatus: {
    isAuthenticated: false,
  },
  tokens: {},
  user: {},
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
