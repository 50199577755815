const Joi = require("joi");

// Schemas

const createSchema = Joi.object({
  email: Joi.string().required(),
  first_name: Joi.string(),
  middle_name: Joi.string().allow(null, ""),
  last_name: Joi.string(),
  display_name: Joi.string().allow(null, ""),
  role: Joi.number().integer(),
});

const createArraySchema = Joi.array().items(createSchema);

const inviteSchema = Joi.object({
  email: Joi.string().required(),
});

const inviteArraySchema = Joi.array().items(createSchema);

const updateSchema = Joi.object({
  email: Joi.string().allow(null, ""),
  first_name: Joi.string().allow(null, ""),
  middle_name: Joi.string().allow(null, ""),
  last_name: Joi.string().allow(null, ""),
  display_name: Joi.string().allow(null, ""),
  settings: Joi.object().keys({
    timezone_id: Joi.number().allow(null, ""),
    date_format: Joi.number().allow(null, ""),
    time_format: Joi.number().allow(null, ""),
  }),
});

const updateCredentialSchema = Joi.object({
  current_password: Joi.string().required(),
  new_password: Joi.string().required(),
  confirm_password: Joi.string().required(),
});

const updateGoogleAPISchema = Joi.object({
  code: Joi.string().required(),
  scope: Joi.string().required(),
});

export {
  createSchema,
  createArraySchema,
  inviteSchema,
  inviteArraySchema,
  updateSchema,
  updateCredentialSchema,
  updateGoogleAPISchema,
};
