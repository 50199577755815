import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateCategoryError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Category Operation",
    type: "warn",
  });
}
function notifyCreateCategorySuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Category successfully created.",
    title: "Category Operation",
    type: "success",
  });
}
function notifyEnableCategoryError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Category Operation",
    type: "warn",
  });
}
function notifyEnableCategorySuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Category successfully enabled.",
    title: "Category Operation",
    type: "success",
  });
}
function notifyDisableCategoryError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Category Operation",
    type: "warn",
  });
}
function notifyDisableCategorySuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Category successfully disabled.",
    title: "Category Operation",
    type: "success",
  });
}
function notifyRemoveCategoryError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Category Operation",
    type: "warn",
  });
}
function notifyRemoveCategorySuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Category successfully removed.",
    title: "Category Operation",
    type: "success",
  });
}
function notifyUpdateCategoryError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Category Operation",
    type: "warn",
  });
}
function notifyUpdateCategorySuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Category successfully updated.",
    title: "Category Operation",
    type: "success",
  });
}

export {
  notifyCreateCategoryError,
  notifyCreateCategorySuccess,
  notifyEnableCategoryError,
  notifyEnableCategorySuccess,
  notifyDisableCategoryError,
  notifyDisableCategorySuccess,
  notifyRemoveCategoryError,
  notifyRemoveCategorySuccess,
  notifyUpdateCategoryError,
  notifyUpdateCategorySuccess,
};
