import { createSchema, updateSchema } from "@/schemas/schemaClients";
import { setPath } from "@/services/helpers";
import {
  notifyCreateClientError,
  notifyCreateClientSuccess,
  notifyDisableClientError,
  notifyDisableClientSuccess,
  notifyEnableClientError,
  notifyEnableClientSuccess,
  notifyRemoveClientError,
  notifyRemoveClientSuccess,
  notifyUpdateClientError,
  notifyUpdateClientSuccess,
} from "@/services/notification/notificationClient";
import httpClient from "@/store/httpClient";

const endpoint = "/a/clients";

// Actions
const actions = {
  async assignItem({ commit }, payload) {
    commit("START_LOADING", "assignItemOps");

    const { error, value } = updateSchema.validate(payload.client);

    if (!error) {
      return httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("FLUSH_FIELD", "assignItemPayload");
            commit("TRIM_OPTIONS", response);
            commit("STOP_LOADING", "assignItemOps");
            notifyUpdateClientSuccess();
            commit("HIDE_VIEW_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "assignItemOps");
          notifyUpdateClientError(error);
        });
    } else {
      commit("STOP_LOADING", "assignItemOps");
      console.log(error);
      notifyUpdateClientError(error);
    }
  },
  async createItems({ commit }, payload) {
    commit("START_LOADING", "createOps");

    const { error, value } = createSchema.validate(payload);

    if (!error) {
      return httpClient
        .post(endpoint, [value])
        .then((response) => {
          if (response.status === 201) {
            commit("APPEND_ITEMS", response);
            commit("FLUSH_FIELD", "createPayload");
            commit("STOP_LOADING", "createOps");
            notifyCreateClientSuccess();
            commit("HIDE_CREATE_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "createOps");
          notifyCreateClientError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "createOps");
      notifyCreateClientError(error);
    }
  },
  async disableItem({ commit }, payload) {
    commit("START_LOADING", "disableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/disable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "disableOps");
          notifyDisableClientSuccess();
          commit("HIDE_DISABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "disableOps");
        notifyDisableClientError(error);
      });
  },
  async enableItem({ commit }, payload) {
    commit("START_LOADING", "enableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/enable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "enableOps");
          notifyEnableClientSuccess();
          commit("HIDE_ENABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "enableOps");
        notifyEnableClientError(error);
      });
  },
  async removeItem({ commit }, payload) {
    commit("START_LOADING", "removeOps");

    httpClient
      .delete(`${endpoint}/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_ITEM", response);
          commit("STOP_LOADING", "removeOps");
          notifyRemoveClientSuccess();
          commit("HIDE_REMOVE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "removeOps");
        notifyRemoveClientError(error);
      });
  },
  async retrieveItems({ commit }, params) {
    commit("START_LOADING", "retrieveClientOps");

    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "retrieveClientOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveClientOps");
      });
  },
  async retrieveItemsUnassigned({ commit }, options) {
    commit("START_LOADING", "retrieveClientUnassignedOps");

    let params = {};

    if (options.meta.page == null) {
      params = {
        page: 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else if (options.meta.page != null && options.meta.has_next) {
      params = {
        page: options.meta.page + 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else {
      return;
    }

    httpClient
      .get(`${endpoint}/unassigned`, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("APPEND_ITEMS", response);
          commit("UPDATE_PAGINATION_METADATA", {
            form: "retrieveClientUnassignedOps",
            field: "meta",
            response: response,
          });
          commit("STOP_LOADING", "retrieveClientUnassignedOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveClientUnassignedOps");
      });
  },
  async searchItems({ commit }, params) {
    commit("START_LOADING", "searchOps");

    httpClient
      .get(`${endpoint}/search`, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "searchOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "searchOps");
      });
  },
  async unassignItem({ commit }, payload) {
    commit("START_LOADING", "unassignOps");

    const { error, value } = updateSchema.validate(payload.client);

    if (!error) {
      return httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("FLUSH_FIELD", "unassignPayload");
            commit("GROW_OPTIONS", response);
            commit("STOP_LOADING", "unassignOps");
            notifyUpdateClientSuccess();
            commit("HIDE_UNASSIGN_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "unassignOps");
          notifyUpdateClientError(error);
        });
    } else {
      commit("STOP_LOADING", "unassignOps");
      console.log(error);
      notifyUpdateClientError(error);
    }
  },
  async updateItem({ commit }, payload) {
    commit("START_LOADING", "updateOps");

    const { error, value } = updateSchema.validate(payload.client);

    if (!error) {
      return httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ITEMS", response);
            commit("STOP_LOADING", "updateOps");
            notifyUpdateClientSuccess();
            commit("HIDE_VIEW_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateOps");
          notifyUpdateClientError(error);
        });
    } else {
      commit("STOP_LOADING", "updateOps");
      console.log(error);
      notifyUpdateClientError(error);
    }
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = false;
  },
  HIDE_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = false;
  },
  HIDE_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_UNASSIGN_OPS_DIALOG(state) {
    state.unassignOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = true;
  },
  SHOW_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = true;
  },
  SHOW_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_UNASSIGN_OPS_DIALOG(state) {
    state.unassignOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    response.data.items.forEach((item) => {
      state.clients.push(item);
    });
  },
  TRIM_OPTIONS(state, response) {
    state.clients = state.clients.filter(
      (element) => element.id != response.data.id
    );
  },
  GROW_OPTIONS(state, response) {
    state.clients.push(response.data);
  },
  BULK_OPS_UPDATE(state, payload) {
    state.bulkPayload = Object.assign((state.bulkPayload = payload));
  },
  FLUSH_ITEMS(state) {
    state.clients = [];
  },
  FLUSH_FIELD(state, name) {
    state[name] = Object.assign({}, {});
  },
  REMOVE_ITEM(state, response) {
    state.clients = state.clients.filter(
      (element) => element.id != response.data.id
    );
  },
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  FLUSH_ITEM(state) {
    state.client = Object.assign({}, {});
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveClientOps.serverItemsLength = response.data.meta.total;
    state.retrieveOps.serverItemsLength = response.data.meta.total;
    state.retrieveClientUnassignedOps.serverItemsLength =
      response.data.meta.total;
  },
  UPDATE_PAGINATION_METADATA(state, payload) {
    state[payload.form][payload.field] = Object.assign(
      {},
      payload.response.data.meta
    );
  },
  UPDATE_ITEMS(state, response) {
    let index = state.clients.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.clients[index], response.data);
    }
  },
  UPDATE_ASSIGNED_CLIENTS(state, response) {
    response;
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
  UPDATE_ITEM(state, response) {
    let index = state.clients.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.clients[index], response.data);
    }
  },
  UPDATE_ITEM_FORM(state, { payload }) {
    if (payload.name != null) {
      if (!payload.property == null) {
        state[payload.form][payload.name] = payload.item[payload.property];
      } else {
        state[payload.form][payload.name] = payload.item;
      }
    } else {
      if (!payload.property == null) {
        state[payload.form] = payload.item[payload.property];
      } else {
        state[payload.form] = payload.item;
      }
    }
  },
};
// State
const state = {
  clients: [],
  client: {},
  bulkPayload: [],
  searchItems: [],
  assignItemPayload: {},
  unassignPayload: {},
  createPayload: {},
  disablePayload: {},
  enablePayload: {},
  removePayload: {},
  updatePayload: {},
  retrieveClientUnassignedOps: {
    isLoading: false,
    serverItemsLength: 0,
    meta: {
      has_next: null,
      page: null,
      pages: null,
      total: null,
    },
  },
  retrieveClientOps: {
    isLoading: false,
    serverItemsLength: 0,
    meta: {
      has_next: null,
      page: null,
      pages: null,
      total: null,
    },
  },
  assignItemOps: {
    isLoading: false,
    payload: {},
  },
  unassignOps: {
    dialog: false,
    isLoading: false,
    payload: {},
  },
  createOps: {
    dialog: false,
    isLoading: false,
  },
  disableOps: {
    dialog: false,
    isLoading: false,
  },
  enableOps: {
    dialog: false,
    isLoading: false,
  },
  removeOps: {
    dialog: false,
    isLoading: false,
  },
  retrieveOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  searchOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    isLoading: false,
  },
  updateClientOps: {
    dialog: false,
    isLoading: false,
  },
  viewOps: {
    dialog: false,
    isLoading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
