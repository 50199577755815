<template>
    <v-app>
        <notifications
            :duration="notificationConfig.duration"
            :group="notificationConfig.group"
            :max="notificationConfig.max"
        />

        <!-- Navigation drawer -->
        <component :is="drawer" />

        <!-- Header navigation -->
        <component :is="header" />

        <!-- Footer navigation -->
        <FooterNav />

        <!-- Routed page view -->
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    components: {
        SideNavAdmin: () => import("@/components/layouts/SideNavAdmin"),
        SideNavUser: () => import("@/components/layouts/SideNavUser"),
        FooterNav: () => import("@/components/layouts/FooterNav"),
        HeaderNav: () => import("@/components/layouts/HeaderNav"),
    },
    computed: {
        drawer() {
            if (this.$route.meta.pageType === "user") {
                return "SideNavUser";
            } else if (this.$route.meta.pageType === "admin") {
                return "SideNavAdmin";
            } else {
                return null;
            }
        },
        header() {
            if (this.internalPageTypes.includes(this.$route.meta.pageType)) {
                return "HeaderNav";
            } else {
                return null;
            }
        },
    },
    data: () => ({
        internalPageTypes: ["user", "admin"],
        notificationConfig: {
            duration: 4500,
            group: "notificationFlash",
            max: 5,
        },
    }),
    name: "App",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

<style>
header {
    background-color: #f5f5f5 !important;
}
nav {
    background-color: #f5f5f5 !important;
}

#app {
    background-color: #f5f5f5 !important;
}

/* Global overrides */
.v-input {
    /* v-text-field */
    border-radius: 2px !important;
}
.row {
    padding: 0 !important;
}
</style>
