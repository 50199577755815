import { createSchema, updateSchema } from "@/schemas/schemaCustomers";
import { setPath } from "@/services/helpers";
import {
  notifyCreateCustomerError,
  notifyCreateCustomerSuccess,
  notifyDisableCustomerError,
  notifyDisableCustomerSuccess,
  notifyEnableCustomerError,
  notifyEnableCustomerSuccess,
  notifyRemoveCustomerError,
  notifyRemoveCustomerSuccess,
  notifyUpdateCustomerError,
  notifyUpdateCustomerSuccess,
} from "@/services/notification/notificationCustomer";
import httpClient from "@/store/httpClient";

const endpoint = "/a/customers";

// Actions
const actions = {
  async createItems({ commit }, payload) {
    commit("START_LOADING", "createOps");

    const { error, value } = createSchema.validate(payload);

    if (!error) {
      return httpClient
        .post(endpoint, [value])
        .then((response) => {
          if (response.status === 201) {
            commit("APPEND_ITEMS", response);
            commit("FLUSH_FIELD", "createPayload");
            commit("STOP_LOADING", "createOps");
            notifyCreateCustomerSuccess();
            commit("HIDE_CREATE_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "createOps");
          notifyCreateCustomerError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "createOps");
      notifyCreateCustomerError(error);
    }
  },
  async disableItem({ commit }, payload) {
    commit("START_LOADING", "disableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/disable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "disableOps");
          notifyDisableCustomerSuccess();
          commit("HIDE_DISABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "disableOps");
        notifyDisableCustomerError(error);
      });
  },
  async enableItem({ commit }, payload) {
    commit("START_LOADING", "enableOps");

    httpClient
      .get(`${endpoint}/${payload.id}/enable`)
      .then((response) => {
        if (response.status === 200) {
          commit("UPDATE_ITEMS", response);
          commit("STOP_LOADING", "enableOps");
          notifyEnableCustomerSuccess();
          commit("HIDE_ENABLE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "enableOps");
        notifyEnableCustomerError(error);
      });
  },
  async removeItem({ commit }, payload) {
    commit("START_LOADING", "removeOps");

    httpClient
      .delete(`${endpoint}/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("REMOVE_ITEM", response);
          commit("STOP_LOADING", "removeOps");
          notifyRemoveCustomerSuccess();
          commit("HIDE_REMOVE_OPS_DIALOG");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "removeOps");
        notifyRemoveCustomerError(error);
      });
  },
  async retrieveItems({ commit }, params) {
    commit("FLUSH_ITEMS");
    commit("START_LOADING", "retrieveOps");
    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "retrieveOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveOps");
      });
  },
  async retrieveItem({ commit }, params) {
    commit("START_LOADING", "retrieveItemOps");

    httpClient.get(`${endpoint}/${params.id}`).then((response) => {
      commit("POPULATE_ITEM", {
        form: "customer",
        item: response.data,
      });
    });
  },
  async searchItems({ commit }, params) {
    commit("START_LOADING", "searchOps");

    httpClient
      .get(`${endpoint}/search`, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("FLUSH_ITEMS");
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "searchOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "searchOps");
      });
  },
  async updateItem({ commit }, payload) {
    commit("START_LOADING", "updateOps");

    const { error, value } = updateSchema.validate(payload.customer);

    if (!error) {
      return httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ITEMS", response);
            commit("STOP_LOADING", "updateOps");
            notifyUpdateCustomerSuccess();
            commit("HIDE_VIEW_OPS_DIALOG");

            return response;
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "updateOps");
          notifyUpdateCustomerError(error);
        });
    } else {
      console.log(error);
      commit("STOP_LOADING", "updateOps");
      notifyUpdateCustomerError(error);
    }
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = false;
  },
  HIDE_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = false;
  },
  HIDE_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DISABLE_OPS_DIALOG(state) {
    state.disableOps.dialog = true;
  },
  SHOW_ENABLE_OPS_DIALOG(state) {
    state.enableOps.dialog = true;
  },
  SHOW_REMOVE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    // state.customers = []; // Flush array before loading data
    response.data.items.forEach((item) => {
      state.customers.push(item);
    });
  },
  BULK_OPS_UPDATE(state, payload) {
    state.bulkPayload = Object.assign((state.bulkPayload = payload));
  },
  FLUSH_ITEMS(state) {
    state.customers = [];
  },
  FLUSH_FIELD(state, name) {
    state[name] = Object.assign({}, {});
  },
  REMOVE_ITEM(state, response) {
    state.customers = state.customers.filter(
      (element) => element.id != response.data.id
    );
  },
  UPDATE_ITEM(state, response) {
    let index = state.customers.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.customers[index], response.data);
    }
  },
  UPDATE_ITEMS(state, response) {
    let index = state.customers.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.customers[index], response.data);
    }
  },
  APPEND_SEARCH_ITEMS(state, response) {
    if (response.data.items.length > 0) {
      state.searchItems = []; // Flush array before loading data
      response.data.items.forEach((item) => {
        state.searchItems.push(item);
      });
    } else {
      console.log("No matching records found.");
    }
  },
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  FLUSH_ITEM(state) {
    state.customer = Object.assign({}, {});
  },
  STRIKE_ITEM(state, payload) {
    let customerIndex = state.customers.findIndex(
      (item) => item.id == payload.customer.id
    );

    if (customerIndex !== -1) {
      let clientIndex = state.customers[customerIndex].clients.findIndex(
        (item) => item.id == payload.client.id
      );

      if (clientIndex !== -1) {
        state.customers[customerIndex].clients.splice(clientIndex, 1);
      }
    }
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveOps.serverItemsLength = response.data.meta.total;
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const state = {
  customers: [],
  customer: {},
  bulkPayload: [],
  createPayload: {},
  searchItems: [],
  serverItemsLength: 0,
  removePayload: {},
  disablePayload: {},
  enablePayload: {},
  createOps: {
    dialog: false,
    isLoading: false,
  },
  removeOps: {
    dialog: false,
    isLoading: false,
  },
  disableOps: {
    dialog: false,
    isLoading: false,
  },
  enableOps: {
    dialog: false,
    isLoading: false,
  },
  retrieveOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  retrieveItemOps: {
    isLoading: false,
  },
  searchOps: {
    isLoading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    isLoading: false,
  },
  viewOps: {
    dialog: false,
    isLoading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
