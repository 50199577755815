const Joi = require("joi");

// Schemas
const updateSelfSchema = Joi.object({
  email: Joi.string(),
  first_name: Joi.string(),
  middle_name: Joi.string().allow(null, ""),
  last_name: Joi.string(),
  display_name: Joi.string().allow(null, ""),
  settings: Joi.object().keys({
    timezone_id: Joi.number().allow(null, ""),
    date_format: Joi.number().allow(null, ""),
    time_format: Joi.number().allow(null, ""),
  }),
});

const updateSelfCredentialSchema = Joi.object({
  current_password: Joi.string().required(),
  new_password: Joi.string().required(),
  confirm_password: Joi.string().required(),
});

export { updateSelfSchema, updateSelfCredentialSchema };
