import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateTrackerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Tracker Operation",
    type: "warn",
  });
}
function notifyCreateTrackerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Tracker successfully created.",
    title: "Tracker Operation",
    type: "success",
  });
}
function notifyEnableTrackerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Tracker Operation",
    type: "warn",
  });
}
function notifyEnableTrackerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Tracker successfully enabled.",
    title: "Tracker Operation",
    type: "success",
  });
}
function notifyDisableTrackerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Tracker Operation",
    type: "warn",
  });
}
function notifyDisableTrackerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Tracker successfully disabled.",
    title: "Tracker Operation",
    type: "success",
  });
}
function notifyRemoveTrackerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Tracker Operation",
    type: "warn",
  });
}
function notifyRemoveTrackerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Tracker successfully removed.",
    title: "Tracker Operation",
    type: "success",
  });
}
function notifyUpdateTrackerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Tracker Operation",
    type: "warn",
  });
}
function notifyUpdateTrackerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Tracker successfully updated.",
    title: "Tracker Operation",
    type: "success",
  });
}

export {
  notifyCreateTrackerError,
  notifyCreateTrackerSuccess,
  notifyEnableTrackerError,
  notifyEnableTrackerSuccess,
  notifyDisableTrackerError,
  notifyDisableTrackerSuccess,
  notifyRemoveTrackerError,
  notifyRemoveTrackerSuccess,
  notifyUpdateTrackerError,
  notifyUpdateTrackerSuccess,
};
