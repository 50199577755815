import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateCustomerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Customer Operation",
    type: "warn",
  });
}
function notifyCreateCustomerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Customer successfully created.",
    title: "Customer Operation",
    type: "success",
  });
}
function notifyEnableCustomerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Customer Operation",
    type: "warn",
  });
}
function notifyEnableCustomerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Customer successfully enabled.",
    title: "Customer Operation",
    type: "success",
  });
}
function notifyDisableCustomerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Customer Operation",
    type: "warn",
  });
}
function notifyDisableCustomerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Customer successfully disabled.",
    title: "Customer Operation",
    type: "success",
  });
}
function notifyRemoveCustomerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Customer Operation",
    type: "warn",
  });
}
function notifyRemoveCustomerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Customer successfully removed.",
    title: "Customer Operation",
    type: "success",
  });
}
function notifyUpdateCustomerError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Customer Operation",
    type: "warn",
  });
}
function notifyUpdateCustomerSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Customer successfully updated.",
    title: "Customer Operation",
    type: "success",
  });
}

export {
  notifyCreateCustomerError,
  notifyCreateCustomerSuccess,
  notifyEnableCustomerError,
  notifyEnableCustomerSuccess,
  notifyDisableCustomerError,
  notifyDisableCustomerSuccess,
  notifyRemoveCustomerError,
  notifyRemoveCustomerSuccess,
  notifyUpdateCustomerError,
  notifyUpdateCustomerSuccess,
};
