import { setPath } from "@/services/helpers";
import ReportServices from "@/services/ReportHelpers";
import httpClient from "@/store/httpClient";
import { DateTime } from "luxon";
const endpoint = "/u/trackers/reports";

// Actions
const actions = {
  async retrieveReport({ commit }, params) {
    commit("START_LOADING", "retrieveOps");

    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          //   commit("APPEND_ITEMS", response);
          commit("COMPUTE_FIELDS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("STOP_LOADING", "retrieveOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveOps");
      });
  },
  async downloadItem({ commit }, params) {
    commit("START_LOADING", "downloadOps");
    console.log(params);

    httpClient.get(`${endpoint}/generate`, { params }).then((response) => {
      let fileName = response.data.filename;
      httpClient
        .get(`${endpoint}/download`, {
          params: {
            directory: response.data.directory,
            filename: response.data.filename,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let downloadLink = document.createElement("a");
            // let fileName = `report_${DateTime.utc().toFormat(
            //   "yyyyLLdd_HHmmss.SSS"
            // )}.xlsx`;
            downloadLink.href = fileURL;
            downloadLink.setAttribute("Download", fileName);
            downloadLink.setAttribute("id", "download_link");
            document.body.appendChild(downloadLink);
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloadLink.remove();
            commit("STOP_LOADING", "downloadOps");
          }
        })
        .catch((error) => {
          console.log(error);
          commit("STOP_LOADING", "downloadOps");
        });
    });
  },
};

// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    state.items = []; // Flush array before loading data
    response.data.items.forEach((item) => {
      state.items.push(item);
    });
  },
  POPULATE_ITEM(state, payload) {
    state[payload.form] = Object.assign({}, payload.item);
  },
  RESET_STATE(state, { payload }) {
    Object.assign(state[payload.name], {});
  },
  COMPUTE_FIELDS(state, response) {
    state.items = []; // Flush array before loading data
    response.data.items.forEach((item) => {
      let start = DateTime.fromISO(item.datetime_start);
      let end = DateTime.fromISO(item.datetime_end);

      item.start_date = ReportServices.formatDate(start, "MM/DD/YYYY");
      item.start_time = ReportServices.formatTime(start, "HH:mm:ss");
      item.end_date = ReportServices.formatDate(end, "MM/DD/YYYY");
      item.end_time = ReportServices.formatTime(end, "HH:mm:ss");

      let difference = end.diff(start);
      let seconds = difference.milliseconds / 1000;
      let minutes = seconds / 60;

      item.total_time = ReportServices.formatDuration(difference);
      item.total_hours = minutes / 60;

      item.billable = ReportServices.computeBillable(
        minutes,
        item.customer.rate
      );
    });

    response.data.items.forEach((item) => {
      state.items.push(item);
    });
  },
  FLUSH_ITEMS(state) {
    state.items = [];
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
  UPDATE_ITEM_FORM(state, { payload }) {
    state[payload.form][payload.name] = payload.item[payload.property];
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveOps.serverItemsLength = response.data.meta.total;
  },
};
// State
const initialState = {
  items: [],
  item: {},
  createPayload: {},
  retrievePayload: {},
  createOps: {
    isLoading: false,
  },
  downloadOps: {
    isLoading: false,
  },
  retrieveOps: {
    isLoading: false,
  },
};

const state = { ...initialState };

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
