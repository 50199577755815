import { setPath } from "@/services/helpers";
import httpClient from "@/store/httpClient";

const endpoint = "/u/customers";

// Actions
const actions = {
  async retrieveItems({ commit }, options) {
    commit("START_LOADING", "retrieveCustomerOps");

    let params = {};

    if (options.meta.page == null) {
      params = {
        page: 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else if (options.meta.page != null && options.meta.has_next) {
      params = {
        page: options.meta.page + 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else {
      return;
    }

    httpClient
      .get(endpoint, { params })
      .then((response) => {
        if (response.status === 200) {
          commit("APPEND_ITEMS", response);
          commit("UPDATE_PAGINATION_METADATA", {
            form: "retrieveCustomerOps",
            field: "meta",
            response: response,
          });
          commit("STOP_LOADING", "retrieveCustomerOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "retrieveCustomerOps");
      });
  },
  async retrieveItems_({ commit }, options) {
    commit("START_LOADING", "retrieveCustomerOps");
    commit("STOP_LOADING", "retrieveCustomerOps");

    let params = {};

    if (options.meta.page == null) {
      params = {
        page: 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else if (options.meta.page != null && options.meta.has_next) {
      params = {
        page: options.meta.page + 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else {
      return;
    }

    return httpClient.get(endpoint, { params });
  },
  async searchItem({ commit, dispatch }, options) {
    commit("START_LOADING", "searchOps");

    let params = {};

    if (options.meta.page == null) {
      params = {
        term: encodeURI(options.term),
        page: 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else if (options.meta.page != null && options.meta.has_next) {
      params = {
        term: encodeURI(options.term),
        page: options.meta.page + 1,
        page_size: 15,
        order_by: options.order_by,
      };
    } else {
      return;
    }

    httpClient
      .post(`${endpoint}/search`, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch("appendSearchItems", response);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("STOP_LOADING", "searchOps");
      });
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].isLoading = true;
  },
  STOP_LOADING(state, name) {
    state[name].isLoading = false;
  },
  //   Dialog operations
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },
  // Data operations
  APPEND_ITEMS(state, response) {
    // state.customers = []; // Flush array before loading data
    response.data.items.forEach((item) => {
      state.customers.push(item);
    });
  },
  FLUSH_ITEMS(state) {
    state.customers = [];
  },
  UPDATE_ITEM(state, response) {
    let index = state.customers.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.customers[index], response.data);
    }
  },
  APPEND_SEARCH_ITEMS(state, response) {
    if (response.data.items.length > 0) {
      state.searchItems = []; // Flush array before loading data
      response.data.items.forEach((item) => {
        state.searchItems.push(item);
      });
    } else {
      console.log("No matching records found.");
    }
  },
  POPULATE_ITEM(state, obj) {
    state.customer = Object.assign({}, state.customers[obj.index]);
  },
  FLUSH_ITEM(state) {
    state.customer = Object.assign({}, {});
  },
  //   Pagination operations
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveCustomerOps.serverItemsLength = response.data.meta.total;
  },
  UPDATE_PAGE_NUMBER(state, response) {
    state.retrieveCustomerOps.page = response.data.meta.page;
  },
  UPDATE_PAGINATION_METADATA(state, payload) {
    state[payload.form][payload.field] = Object.assign(
      {},
      payload.response.data.meta
    );
  },
  //   Form operations
  UPDATE_FORM(state, { payload }) {
    setPath(state, payload.form, payload.value);
  },
};
// State
const state = {
  customers: [],
  customer: {},
  searchItems: [],
  serverItemsLength: 0,
  retrieveCustomerOps: {
    isLoading: true,
    serverItemsLength: 0,
    meta: {
      has_next: null,
      page: null,
      pages: null,
      total: null,
    },
  },
  searchCustomerOps: null,
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
