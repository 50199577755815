import { createSchema, updateSchema } from "@/schemas/schemaTrackers";
import httpClient from "@/store/httpClient";

const endpoint = "/trackers";

// Actions
const actions = {
  async appendSearchItems({ commit }, response) {
    commit("APPEND_SEARCH_ITEMS", response);
  },
  hideViewDialog({ commit }) {
    commit("HIDE_VIEWOPS_DIALOG");
  },
  hideCreateDialog({ commit }) {
    commit("HIDE_CREATEOPS_DIALOG");
  },
  showCreateDialog({ commit }) {
    commit("SHOW_CREATEOPS_DIALOG");
  },
  async createItem({ commit }, payload) {
    const { error, value } = createSchema.validate(payload);

    if (!error) {
      return httpClient
        .post(endpoint, value)
        .then((response) => {
          if (response.status === 200) {
            commit("APPEND_ITEM", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log(error);
    }
  },
  async retrieveItems({ commit }, options) {
    commit("START_LOADING", "retrieveOps");
    httpClient
      .get(endpoint, {
        params: {
          page: options.page,
          page_size: options.itemsPerPage,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("APPEND_ITEMS", response);
          commit("UPDATE_SERVER_ITEMS_LENGTH", response);
          commit("START_LOADING", "retrieveOps");
        }
      })
      .catch((error) => {
        console.log(error);
        commit("START_LOADING", "retrieveOps");
      });
  },
  async searchItem({ commit, dispatch }, payload) {
    commit("TOGGLE_LOADER");
    httpClient
      .post(`${endpoint}/search`, {
        name: payload,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch("appendSearchItems", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateItemAction({ commit }, payload) {
    const { validationError, value } = updateSchema.validate(payload.customer);

    if (!validationError) {
      httpClient
        .put(`${endpoint}/${payload.id}`, value)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ITEM", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log(validationError);
    }
  },
  viewItem({ commit }, data) {
    commit("POPULATE_ITEM", data);
    commit("SHOW_VIEWOPS_DIALOG");
  },
};
// Getters
const getters = {};
// Mutations
const mutations = {
  // Loader operations
  START_LOADING(state, name) {
    state[name].loading = true;
  },
  STOP_LOADING(state, name) {
    state[name].loading = false;
  },
  APPEND_ITEMS(state, response) {
    state.trackers = []; // Flush array before loading data
    response.data.items.forEach((item) => {
      state.trackers.push(item);
    });
  },
  APPEND_SEARCH_ITEMS(state, response) {
    console.log(response);
    if (response.data.items.length > 0) {
      state.searchItems = []; // Flush array before loading data
      response.data.items.forEach((item) => {
        state.searchItems.push(item);
      });
    } else {
      console.log("No matching records found.");
    }
  },
  UPDATE_SERVER_ITEMS_LENGTH(state, response) {
    state.retrieveOps.serverItemsLength = response.data.meta.total;
  },
  TOGGLE_LOADER(state) {
    state.retrieveOps.loading = !state.retrieveOps.loading;
  },
  POPULATE_ITEM(state, obj) {
    state.tracker = Object.assign({}, state.trackers[obj.index]);
  },
  //   Dialog operations
  SHOW_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = true;
  },
  SHOW_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = true;
  },
  SHOW_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = true;
  },
  HIDE_CREATE_OPS_DIALOG(state) {
    state.createOps.dialog = false;
  },
  HIDE_DELETE_OPS_DIALOG(state) {
    state.removeOps.dialog = false;
  },
  HIDE_VIEW_OPS_DIALOG(state) {
    state.viewOps.dialog = false;
  },

  //   Form operations
  UPDATE_FORM(state, { payload }) {
    state[payload.form][payload.event.target.id] = payload.event.target.value;
  },
  UPDATE_ITEM(state, response) {
    let index = state.trackers.findIndex(
      (element) => element.id == response.data.id
    );
    if (index !== -1) {
      Object.assign(state.trackers[index], response.data);
    }
  },
  UPDATE_CREATE(state, { e }) {
    console.log(e);
    console.log(state.createOps.staging);
    // state.createOps.staging[e.target.id] = e.target.value;
  },
};
// State
const state = {
  trackers: [],
  tracker: {},
  searchItems: [],
  serverItemsLength: 0,
  createOps: {
    dialog: false,
    loading: false,
  },
  removeOps: {
    dialog: false,
    loading: false,
  },
  retrieveOps: {
    loading: false,
    serverItemsLength: 0,
  },
  updateOps: {
    dialog: false,
    loading: false,
  },
  viewOps: {
    dialog: false,
    loading: false,
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
