import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // Redirects
  {
    path: "/",
    redirect: { name: "Home" },
  },
  // Admin pages
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "customers" */ "@/views/admin/Admin.vue"),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Admin",
    },
  },
  {
    path: "/admin/customers",
    name: "Customers",
    component: () =>
      import(/* webpackChunkName: "customers" */ "@/views/admin/Customers.vue"),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Customers",
    },
  },
  {
    path: "/admin/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "clients" */ "@/views/admin/Clients.vue"),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Clients",
    },
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "categories" */ "@/views/admin/Categories.vue"
      ),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Categories",
    },
  },
  {
    path: "/admin/tasks",
    name: "Tasks",
    component: () =>
      import(/* webpackChunkName: "tasks" */ "@/views/admin/Tasks.vue"),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Tasks",
    },
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/admin/Users.vue"),
    meta: {
      pageType: "admin",
      requiresAuth: true,
      title: "Users",
    },
  },
  // User pages
  {
    path: "/app",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/user/Dashboard.vue"),
    meta: {
      pageType: "user",
      requiresAuth: true,
      title: "Home",
    },
  },
  {
    path: "/app/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/views/user/Calendar.vue"),
    meta: {
      pageType: "user",
      requiresAuth: true,
      title: "Calendar",
    },
  },
  {
    path: "/app/report",
    name: "Report",
    component: () =>
      import(/* webpackChunkName: "report" */ "@/views/user/Report.vue"),
    meta: {
      pageType: "user",
      requiresAuth: true,
      title: "Report",
    },
  },
  {
    path: "/app/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/user/Profile.vue"),
    meta: {
      pageType: "user",
      requiresAuth: true,
      title: "Profile",
    },
  },
  {
    path: "/app/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "@/views/user/Logout.vue"),
    meta: {
      pageType: "user",
      requiresAuth: true,
      title: "Logout",
    },
  },
  // Public pages
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/public/Login"),
    meta: {
      pageType: "public",
      requiresAuth: false,
      title: "Login",
    },
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: () =>
      import(/* webpackChunkName: "recovery" */ "@/views/public/Recovery"),
    meta: {
      pageType: "public",
      requiresAuth: false,
      title: "Recovery",
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "reset" */ "@/views/public/Reset"),
    meta: {
      pageType: "public",
      requiresAuth: false,
      title: "Reset",
    },
  },
  // Catch all redirects
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/public/404"),
    meta: {
      pageType: "invalid",
      requiresAuth: false,
      title: "404 Not Found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

// Navigation helper methods

// Append page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    to.meta.title
      ? (document.title = `AstraVirtus - ${to.meta.title}`)
      : (document.title = "AstraVirtus");
  });
});

// Navigation guards
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["authsMgmt/isAuthenticated"];

  if (!isAuthenticated) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      next({ name: "Login", query: { redirect: to.path } });
    } else {
      next();
    }
  } else {
    if (to.name == "Login") {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});
