const Joi = require("joi");

const createSchema = Joi.object({
  datetime_start: Joi.date().iso().required(),
  datetime_end: Joi.date().iso().required(),
  category_id: Joi.number().required(),
  customer_id: Joi.number().required(),
  client_id: Joi.number().allow(null, ""),
  task_id: Joi.number().required(),
  description: Joi.string().allow(null, ""),
});

const updateSchema = Joi.object({
  datetime_start: Joi.date().iso().required(),
  datetime_end: Joi.date().iso().required(),
  category_id: Joi.number(),
  customer_id: Joi.number(),
  client_id: Joi.number().allow(null, ""),
  task_id: Joi.number(),
  description: Joi.string().allow(null, ""),
});

export { createSchema, updateSchema };
