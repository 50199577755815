import Vue from "vue";

const notificationGroup = "notificationFlash";

// Helper methods
function isHttpResponse(error) {
  return error.response != undefined;
}

// Notifications
function notifyCreateClientError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Client Operation",
    type: "warn",
  });
}
function notifyCreateClientSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Client successfully created.",
    title: "Client Operation",
    type: "success",
  });
}
function notifyEnableClientError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Client Operation",
    type: "warn",
  });
}
function notifyEnableClientSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Client successfully enabled.",
    title: "Client Operation",
    type: "success",
  });
}
function notifyDisableClientError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Client Operation",
    type: "warn",
  });
}
function notifyDisableClientSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Client successfully disabled.",
    title: "Client Operation",
    type: "success",
  });
}
function notifyRemoveClientError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Client Operation",
    type: "warn",
  });
}
function notifyRemoveClientSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Client successfully removed.",
    title: "Client Operation",
    type: "success",
  });
}
function notifyUpdateClientError(error) {
  Vue.notify({
    group: notificationGroup,
    text: isHttpResponse(error) ? error.response.data.message : error,
    title: "Client Operation",
    type: "warn",
  });
}
function notifyUpdateClientSuccess() {
  Vue.notify({
    group: notificationGroup,
    text: "Client successfully updated.",
    title: "Client Operation",
    type: "success",
  });
}

export {
  notifyCreateClientError,
  notifyCreateClientSuccess,
  notifyEnableClientError,
  notifyEnableClientSuccess,
  notifyDisableClientError,
  notifyDisableClientSuccess,
  notifyRemoveClientError,
  notifyRemoveClientSuccess,
  notifyUpdateClientError,
  notifyUpdateClientSuccess,
};
